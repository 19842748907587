import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import LogRocket from 'logrocket';
LogRocket.init('dale-walden/dalewaldencom');

const Logo = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <svg
        width="192"
        height="23"
        viewBox="0 0 192 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Desktop"
      >
        <g >
            <text y="12" font-family="Arial" font-size="12px" font-style="normal" font-weight="400" fill={fill} style={{whiteSpace: "pre"}}>
                DALE WALDEN
            </text>
        </g>

      </svg>

      <svg
        width="192"
        height="23"
        viewBox="0 0 192 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Mobile"
      >
        <g >
            <text y="12" font-family="Arial" font-size="12px" font-style="normal" font-weight="400" fill={fill} style={{whiteSpace: "pre"}}>
                DALE WALDEN
            </text>
        </g>

      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
